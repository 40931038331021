@font-face {
    font-family: "Frutiger W01";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix");
    src: url("/fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix") format("eot"),
         url("/fonts/0811514e-6660-4043-92c1-23d0b8caaa2f.woff2") format("woff2"),
         url("/fonts/aeae8cac-ba4d-40d2-bbcf-936f2c7dcfe6.woff") format("woff"), 
         url("/fonts/caedf2e6-fbef-4928-b08e-1f975981ae8d.ttf") format("truetype");
}

@font-face {
    font-family: "Frutiger W01";
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix");
    src: url("/fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix") format("eot"),
         url("/fonts/8c92eb68-ce37-4962-a9f4-5b47d336bd1c.woff2") format("woff2"),
         url("/fonts/9bdfdcac-bbd4-46a9-9280-056c9a4438a2.woff") format("woff"),
         url("/fonts/5dae3a6e-9b07-4c2f-aed2-1f5b8951261b.ttf") format("truetype");
}

@font-face {
    font-family: "Frutiger W01";
    font-style: normal;
    font-weight: 900;
    src: url("/fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot?#iefix");
    src: url("/fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot?#iefix") format("eot"),
         url("/fonts/221b3252-186e-4a32-a90b-cc0c3a4817d7.woff2") format("woff2"),
         url("/fonts/a061c7a6-3b14-451e-883c-0f1661ac3d76.woff") format("woff"),
         url("/fonts/558394f3-8981-4be9-8a8a-c23488eb9b6d.ttf") format("truetype");
}

@font-face {
    font-family: "Frutiger W01";
    font-stretch: condensed;
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/e6c921f4-c7f3-4375-a9e2-2a30e1af2014.eot?#iefix");
    src: url("/fonts/e6c921f4-c7f3-4375-a9e2-2a30e1af2014.eot?#iefix") format("eot"),
         url("/fonts/5995e4b5-97ff-4823-bebb-dc150282a2cf.woff2") format("woff2"),
         url("/fonts/bd26bfea-b97f-4b32-b5f6-504be0b1938d.woff") format("woff"),
         url("/fonts/01a184f4-fea6-4abf-805e-1baebb16eefb.ttf") format("truetype");
}

@font-face {
    font-family: "Frutiger W01";
    font-stretch: condensed;
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/d4125907-9465-43ee-b1eb-b9195f69cec7.eot?#iefix");
    src: url("/fonts/d4125907-9465-43ee-b1eb-b9195f69cec7.eot?#iefix") format("eot"),
         url("/fonts/4c4ee705-e593-4456-988d-03a5a2594534.woff2") format("woff2"),
         url("/fonts/e728739a-5d86-4213-9f4f-73394eff3d19.woff") format("woff"),
         url("/fonts/ec9c2bcd-5001-4da7-985e-6697698f55b3.ttf") format("truetype");
}
